var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('loading-overlay',{attrs:{"active":_vm.Loading,"is-full-page":true,"loader":"bars"}}),_c('StaffModal',{attrs:{"modal":_vm.modal,"companyStaffId":_vm.companyStaffId,"edit":_vm.edit,"title":_vm.title},on:{"update:modal":function($event){_vm.modal=$event},"submited":_vm.handleSubmit}}),_c('AlertModal',{attrs:{"modal":_vm.alertModal,"staffList":_vm.staffFile,"fileName":_vm.fileName},on:{"update:modal":function($event){_vm.alertModal=$event}}}),_c('ErrorsModal',{attrs:{"modal":_vm.errosModal,"staffList":_vm.errorsList},on:{"update:modal":function($event){_vm.errosModal=$event}}}),_c('CCard',[_c('CCardHeader',{staticClass:"text-center bg-dark text-white"},[_c('b',[_vm._v(" "+_vm._s(_vm.$t("label.stowageStaff"))+" ")])]),_c('CCardBody',[_c('CCol',{attrs:{"sm":"12"}},[_c('CustomTabs',{attrs:{"active-tab":_vm.activeTab},on:{"update:activeTab":_vm.handleTab}},[_c('CustomTab',{attrs:{"icon":"general","title":_vm.$t('label.companyStaff')}},[_c('CRow',{staticClass:"my-1"},[_c('CCol',{staticClass:"center-field",attrs:{"sm":"4"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.company'),"addLabelClasses":"text-right","horizontal":{ label: 'col-sm-3', input: 'col-sm-9' },"placeholder":_vm.$t('label.select'),"options":_vm.companiesOptions,"disabled":_vm.isCompaniesEmpty,"value":_vm.$v.company.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.company),"is-valid":_vm.hasError(_vm.$v.company)},on:{"update:value":function($event){return _vm.$set(_vm.$v.company, "$model", $event)}}}),_c('loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingCompany),expression:"loadingCompany"}],attrs:{"element":"select"}})],1),_c('CCol',{staticClass:"text-left",attrs:{"sm":"4"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    placement: 'top-end',
                    content: `${_vm.$t('label.download')} ${_vm.$t('label.format')}`,
                  }),expression:"{\n                    placement: 'top-end',\n                    content: `${$t('label.download')} ${$t('label.format')}`,\n                  }"}],attrs:{"shape":"square","color":"excel","href":_vm.getFormatURL,"download":""}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}})],1)],1),_c('CCol',{staticClass:"d-flex align-items-start justify-content-end",attrs:{"sm":"4"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    placement: 'top-end',
                    content: _vm.$t('label.resource'),
                  }),expression:"{\n                    placement: 'top-end',\n                    content: $t('label.resource'),\n                  }"}],attrs:{"shape":"square","color":"add"},on:{"click":_vm.toggleModal}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v(_vm._s(_vm.$t("label.nuevo"))+" ")],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',{attrs:{"bodyWrapper":""}},[_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"sm":"12"}},[_c('vue-dropzone',{ref:"myVueDropzone",attrs:{"id":"dropzone","options":_vm.dropZoneOptions,"useCustomSlot":true},on:{"vdropzone-files-added":_vm.sendingEvent,"vdropzone-removed-file":_vm.deleteFile,"vdropzone-error":_vm.DropzoneError}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_c('CIcon',{attrs:{"name":"cil-cloud-upload"}}),_vm._v(" ¡"+_vm._s(_vm.$t("label.dragAndDropToUploadContent"))+"! ")],1),_c('div',{staticClass:"subtitle justify-content-center"},[_vm._v(" ..."+_vm._s(_vm.$t("label.orClickToSelectFileFromYourComputer"))+" ")])])])],1),_c('CCol',{attrs:{"sm":"11"}},[_c('CInput',{directives:[{name:"uppercase",rawName:"v-uppercase"}],attrs:{"label":_vm.$t('label.description'),"horizontal":"","addLabelClasses":"text-right","placeholder":_vm.$t('label.documentDescription'),"disabled":""},model:{value:(_vm.fileDescription),callback:function ($$v) {_vm.fileDescription=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"fileDescription"}})],1),_c('CCol',{staticClass:"d-flex align-items-start justify-content-end",attrs:{"sm":"1"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                          content: _vm.$t('label.add'),
                          placement: 'top-end',
                        }),expression:"{\n                          content: $t('label.add'),\n                          placement: 'top-end',\n                        }"}],attrs:{"color":"add","size":"sm"},on:{"click":function($event){$event.stopPropagation();return _vm.submitStaffFile.apply(null, arguments)}}},[(!_vm.isSubmit)?_c('div',[_c('CIcon',{attrs:{"name":"checkAlt"}})],1):_vm._e(),(_vm.isSubmit)?_c('div',[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}})]):_vm._e()])],1)],1)],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('hr'),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedStaff,"fields":_vm.fieldsStaff,"loading":_vm.loadingStaff,"column-filter":"","noItemsView":_vm.tableText.noItemsViewText,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":_vm.tableText.itemsPerPage,"hover":"","small":"","sorter":"","pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center align-middle"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(item.Status)+" ")])],1)]}},{key:"Detalle",fn:function({ item }){return [_c('td',{staticClass:"text-center align-middle"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                          appendToBody: true,
                          placement: 'top-end',
                          content: _vm.$t('label.edit') + ' ' + _vm.$t('label.resource'),
                        }),expression:"{\n                          appendToBody: true,\n                          placement: 'top-end',\n                          content: $t('label.edit') + ' ' + $t('label.resource'),\n                        }"}],attrs:{"shape":"square","color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleModal(true, item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1),_c('CustomTab',{attrs:{"icon":"general","title":_vm.$t('label.historyStaff')}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.formatedHistorical,"fields":_vm.fieldsHistorical,"loading":_vm.loadingHistorical,"column-filter":"","noItemsView":_vm.tableText.noItemsViewText,"table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"items-per-page":_vm.tableText.itemsPerPage,"hover":"","small":"","sorter":"","pagination":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center align-middle"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(item.Status)+" ")])],1)]}},{key:"Detalle",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(
                          `${_vm.$t('label.toView')} ${_vm.$t('label.errors')}`
                        ),expression:"\n                          `${$t('label.toView')} ${$t('label.errors')}`\n                        "}],staticClass:"mr-1",attrs:{"shape":"square","color":"watch","size":"sm"},on:{"click":function($event){return _vm.toggleErrors(item)}}},[_c('CIcon',{attrs:{"name":"error"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(`${_vm.$t('label.download')} EXCEL`),expression:"`${$t('label.download')} EXCEL`"}],staticClass:"mr-1",attrs:{"shape":"square","color":"excel","size":"sm"},on:{"click":function($event){return _vm.onBtnExportAll(item.CompanyStaffListJson)}}},[_c('CIcon',{attrs:{"name":"cil-cloud-download"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(`${_vm.$t('label.delete')}`),expression:"`${$t('label.delete')}`"}],staticClass:"mr-1",attrs:{"shape":"square","color":"wipe","size":"sm","disabled":_vm.isDeactivate},on:{"click":function($event){return _vm.deleteConfirmation(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
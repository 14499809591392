<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <StaffModal
      :modal.sync="modal"
      :companyStaffId="companyStaffId"
      :edit="edit"
      :title="title"
      @submited="handleSubmit"
    />
    <AlertModal
      :modal.sync="alertModal"
      :staffList="staffFile"
      :fileName="fileName"
    />
    <ErrorsModal :modal.sync="errosModal" :staffList="errorsList" />
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b> {{ $t("label.stowageStaff") }} </b>
      </CCardHeader>
      <CCardBody>
        <CCol sm="12">
          <CustomTabs :active-tab="activeTab" @update:activeTab="handleTab">
            <CustomTab icon="general" :title="$t('label.companyStaff')">
              <CRow class="my-1">
                <CCol sm="4" class="center-field">
                  <CSelect
                    :label="$t('label.company')"
                    addLabelClasses="text-right"
                    :horizontal="{ label: 'col-sm-3', input: 'col-sm-9' }"
                    :placeholder="$t('label.select')"
                    :options="companiesOptions"
                    :disabled="isCompaniesEmpty"
                    :value.sync="$v.company.$model"
                    :invalid-feedback="errorMessage($v.company)"
                    :is-valid="hasError($v.company)"
                  >
                  </CSelect>
                  <loading v-show="loadingCompany" element="select" />
                </CCol>
                <CCol sm="4" class="text-left">
                  <CButton
                    shape="square"
                    color="excel"
                    :href="getFormatURL"
                    download
                    v-c-tooltip="{
                      placement: 'top-end',
                      content: `${$t('label.download')} ${$t('label.format')}`,
                    }"
                  >
                    <CIcon name="cil-file-excel" />
                  </CButton>
                </CCol>
                <CCol sm="4" class="d-flex align-items-start justify-content-end">
                  <CButton
                    shape="square"
                    color="add"
                    @click="toggleModal"
                    v-c-tooltip="{
                      placement: 'top-end',
                      content: $t('label.resource'),
                    }"
                  >
                    <CIcon name="cil-playlist-add" />{{ $t("label.nuevo") }}
                  </CButton>
                </CCol>

                <CCol sm="12">
                  <CCard bodyWrapper>
                    <CRow>
                      <CCol sm="12" class="mb-3">
                        <vue-dropzone
                          ref="myVueDropzone"
                          id="dropzone"
                          :options="dropZoneOptions"
                          :useCustomSlot="true"
                          v-on:vdropzone-files-added="sendingEvent"
                          v-on:vdropzone-removed-file="deleteFile"
                          v-on:vdropzone-error="DropzoneError"
                        >
                          <div class="dropzone-custom-content">
                            <h3 class="dropzone-custom-title">
                              <CIcon name="cil-cloud-upload" /> ¡{{
                                $t("label.dragAndDropToUploadContent")
                              }}!
                            </h3>
                            <div class="subtitle justify-content-center">
                              ...{{ $t("label.orClickToSelectFileFromYourComputer") }}
                            </div>
                          </div>
                        </vue-dropzone>
                      </CCol>
                      <CCol sm="11">
                        <CInput
                          :label="$t('label.description')"
                          horizontal
                          addLabelClasses="text-right"
                          :placeholder="$t('label.documentDescription')"
                          v-uppercase
                          disabled
                          v-model.trim="fileDescription"
                        />
                      </CCol>
                      <CCol
                        sm="1"
                        class="d-flex align-items-start justify-content-end"
                      >
                        <CButton
                          color="add"
                          v-c-tooltip="{
                            content: $t('label.add'),
                            placement: 'top-end',
                          }"
                          size="sm"
                          @click.stop="submitStaffFile"
                        >
                          <div v-if="!isSubmit">
                            <CIcon name="checkAlt" />
                          </div>
                          <div v-if="isSubmit">
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </div>
                        </CButton>
                      </CCol>
                    </CRow>
                  </CCard>
                </CCol>

                <!-- <CCollapse :show="isSubmit" class="upload-progress">
                  <CCol sm="11" class="mb-3">
                    <CProgress class="upload-progress">
                      <CProgressBar
                        color="success"
                        variant="striped"
                        animated
                        :value="uploadPercent"
                      >
                        {{
                          uploadPercent != 100
                            ? `${uploadPercent}%`
                            : $t("label.readingFile")
                        }}
                      </CProgressBar>
                    </CProgress>
                  </CCol>
                  <CCol sm="1"> </CCol>
                </CCollapse> -->
                <CCol sm="12">
                  <hr />
                  <dataTableExtended
                    class="align-center-row-datatable"
                    :items="formatedStaff"
                    :fields="fieldsStaff"
                    :loading="loadingStaff"
                    column-filter
                    :noItemsView="tableText.noItemsViewText"
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items-per-page="tableText.itemsPerPage"
                    hover
                    small
                    sorter
                    pagination
                  >
                    <template #loading>
                      <loading />
                    </template>
                    <template #Status="{ item }">
                      <td class="text-center align-middle">
                        <CBadge :color="getBadge(item.Status)">
                          {{ item.Status }}
                        </CBadge>
                      </td>
                    </template>
                    <template #Detalle="{ item }">
                      <td class="text-center align-middle">
                        <CButton
                          shape="square"
                          color="edit"
                          size="sm"
                          v-c-tooltip="{
                            appendToBody: true,
                            placement: 'top-end',
                            content: $t('label.edit') + ' ' + $t('label.resource'),
                          }"
                          @click="toggleModal(true, item)"
                        >
                          <CIcon name="pencil" />
                        </CButton>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CRow>
            </CustomTab>
            <CustomTab icon="general" :title="$t('label.historyStaff')">
              <CRow>
                <CCol sm="12">
                  <dataTableExtended
                    class="align-center-row-datatable"
                    :items="formatedHistorical"
                    :fields="fieldsHistorical"
                    :loading="loadingHistorical"
                    column-filter
                    :noItemsView="tableText.noItemsViewText"
                    :table-filter="tableText.tableFilterText"
                    :items-per-page-select="tableText.itemsPerPageText"
                    :items-per-page="tableText.itemsPerPage"
                    hover
                    small
                    sorter
                    pagination
                  >
                    <template #loading>
                      <loading />
                    </template>
                    <template #Status="{ item }">
                      <td class="text-center align-middle">
                        <CBadge :color="getBadge(item.Status)">
                          {{ item.Status }}
                        </CBadge>
                      </td>
                    </template>
                    <template #Detalle="{ item }">
                      <td class="text-center">
                        <CButton
                          shape="square"
                          color="watch"
                          class="mr-1"
                          size="sm"
                          v-c-tooltip="
                            `${$t('label.toView')} ${$t('label.errors')}`
                          "
                          @click="toggleErrors(item)"
                        >
                          <CIcon name="error" />
                        </CButton>
                        <CButton
                          shape="square"
                          class="mr-1"
                          color="excel"
                          size="sm"
                          @click="onBtnExportAll(item.CompanyStaffListJson)"
                          v-c-tooltip="`${$t('label.download')} EXCEL`"
                        >
                          <CIcon name="cil-cloud-download" />
                        </CButton>
                        <CButton
                          shape="square"
                          class="mr-1"
                          color="wipe"
                          size="sm"
                          :disabled="isDeactivate"
                          v-c-tooltip="`${$t('label.delete')}`"
                          @click="deleteConfirmation(item)"
                        >
                          <CIcon name="cil-trash" />
                        </CButton>
                      </td>
                    </template>
                  </dataTableExtended>
                </CCol>
              </CRow>
            </CustomTab>
          </CustomTabs>
        </CCol>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { DateFormater, alertPropertiesHelpers } from "@/_helpers/funciones";
import FileUploadValidation from "@/_validations/staff/FileUploadValidation";
import UpperCase  from '@/_validations/uppercase-directive';
import General from "@/_mixins/general";
import StaffModal from "./add-modal";
import AlertModal from "./alert-modal";
import ErrorsModal from "./errors-modal";
import CustomTabs from "@/components/tabs/CustomTabs";
import CustomTab from "@/components/tabs/CustomTab";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapState } from "vuex";
import Reportes from '@/_mixins/reportes';
import StaffMixin from '@/_mixins/staff';

function fieldsStaff() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      _classes: "text-center",
      filter: false,
    },
    { key: "Name", label: this.$t("label.name")+' (S)', _classes: "text-center" },
    { key: "LastName", label: this.$t("label.lastName")+' (S)', _classes: "text-center" },
    { key: "Cedula", label: this.$t("label.IdNumber"), _classes: "text-center" },
    { key: "Company", label: this.$t("label.company"), _classes: "text-center" },
    { key: "Usuario", label: this.$t("label.user"), _classes: "text-center" },
    { key: "Fecha", label: this.$t("label.date"), _classes: "text-center" },
    { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _styles: "width: 1%;min-width: 45px",
    },
  ];
}
function cellTableStaffClasses() {
  return {
    Nro: "align-middle",
    Name: "align-middle",
    LastName: "align-middle",
    Cedula: "align-middle",
    Company: "align-middle",
    Usuario: "align-middle",
    Fecha: "align-middle",
    Status: "align-middle",
  };
}
function fieldsHistorical() {
  return [
    {
      key: "Nro",
      label: "#",
      _style: "width:1%;",
      _classes: "text-center",
      filter: false,
    },
    { key: "FileName", label: this.$t("label.filename"), _classes: "text-center" },
    { key: "Company", label: this.$t("label.company"), _classes: "text-center" },
    { key: "Resources", label: this.$t("label.resourcesQty"), _classes: "text-center" },
    { key: "RightRows", label: this.$t("label.rightRows"), _classes: "text-center" },
    { key: "WrongRows", label: this.$t("label.wrongRows"), _classes: "text-center" },
    { key: "Usuario", label: this.$t("label.user"), _classes: "text-center" },
    { key: "Fecha", label: this.$t("label.date"), _classes: "text-center" },
    { key: "Status", label: this.$t("label.status"), _classes: "text-center" },
    {
      key: "Detalle",
      label: "",
      sorter: false,
      filter: false,
      _style: "width: 1%;min-width: 135px",
    },
  ];
}
function cellTableHistoricalClasses() {
  return {
    Nro: "align-middle",
    FileName: "align-middle",
    Company: "align-middle",
    Resources: "align-middle",
    RightRows: "align-middle",
    WrongRows: "align-middle",
    Usuario: "align-middle",
    Fecha: "align-middle",
    Status: "align-middle",
  };
}

//DATA
function data() {
  return {
    itemsStaff: [],
    loadingStaff: false,
    loadingHistorical: false,
    loadingCompany: false,
    TpClientId: "7c1f74c3-3b2b-445c-84bf-004a5394983a",
    companies: [],
    company: "",
    fileDescription: "",
    activeTab: 0,
    docFile: null,
    itemsHistorical: [],
    Loading: false,
    modal: false,
    edit: false,
    companyStaffId: "",
    companyStaffItems: {},
    alertModal: false,
    isSubmit: false,
    uploadPercent: 0,
    responsePercent: 0,
    staffFile: [],
    fileName: "",
    errosModal: false,
    errorsList: [],
    isDeactivate: false,
    title: ''
  };
}

//METHOD
function loadData(tab = false) {
  if (!tab) this.Loading = true;

  this.loadingStaff = true;
  this.loadingCompany = true;

  let peticiones = tab
    ? [
        this.$http.ejecutar("GET", "Client-list-by-activity", {
          TpClientId: this.TpClientId,
          Filter: "ACTIVO",
        }),
        this.$http.ejecutar("GET", "CompanyGangs-list", {
          ClientTpId: this.company,
          Filter: "ALL",
        }),
      ]
    : [
        this.$http.ejecutar("GET", "Client-list-by-activity", {
          TpClientId: this.TpClientId,
          Filter: "ACTIVO",
        }),
        this.$http.ejecutar("GET", "CompanyGangs-list", {
          ClientTpId: "",
          Filter: "ALL",
        }),
        this.$http.ejecutar("GET", "CompanyGangsFile-list"),
      ];

  Promise.all(peticiones)
    .then((responses) => {
      this.companies = responses[0].data.data;
      this.itemsStaff = responses[1].data.data;
      if (!tab) {
        this.itemsHistorical = [];
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      if (!tab) this.Loading = false;

      this.loadingStaff = false;
      this.loadingCompany = false;
    });
}
function getStaffList(clientId = "") {
  this.loadingStaff = true;

  this.$http
    .get("CompanyGangs-list", { ClientTpId: clientId, Filter: "ALL" })
    .then((response) => {
      this.itemsStaff = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingStaff = false;
    });
}
function getHistoricalList() {
  this.loadingHistorical = true;

  this.$http
    .get("CompanyGangsFile-list")
    .then((response) => {
      this.itemsHistorical = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.loadingHistorical = false;
    });
}

function sendingEvent(files) {
  let ext = "";
  if (files[0].name) {
    ext = files[0].name.substring(
      files[0].name.lastIndexOf(".") + 1,
      files[0].name.length
    );

    let format = ".xlsx";
    if (`.${ext.toLowerCase()}` == format) {
      this.docFile = files[0];
    } else {
      this.$refs.myVueDropzone.removeAllFiles();
      let msg = `${this.$t("label.docTypeInvalid")}`;
      this.$swal.fire({
        icon: "error",
        title: "",
        text: msg,
      });
    }
  }
}
function deleteFile(file) {
  this.docFile = null;
}
function DropzoneError(file) {
  this.$refs.myVueDropzone.removeFile(file);
}
function handleTab(tab) {
  this.activeTab = tab;
}

function resetData() {
  this.fileDescription = '';
  this.$refs.myVueDropzone.removeAllFiles();
  this.docFile = null;
  this.uploadPercent = 0;
  this.responsePercent = 0;
  this.title= ''
}
function handleSubmit() {
  this.getStaffList(this.company);
}
function toggleModal(edit = false, item = null) {
  if (edit && item) {
    this.edit = edit;
    this.companyStaffId = item.CompanyStaffId;
    this.companyStaffItems = item;
    this.title = `${this.$t("label.edit")} ${this.$t("label.resource")}: ${this.companyStaffItems.Name} ${this.companyStaffItems.LastName}`;
  }else{
    this.title = this.$t("label.nuevo") + " " + this.$t("label.resource");
  }
  this.modal = true;
}
function toggleAlert() {
  this.alertModal = true;
}
async function submitStaffFile() {
  try {
    this.$v.company.$touch();
    if (!this.company || !this.docFile) {
      throw this.$t("label.errorsPleaseCheck");
    }
    this.isSubmit = true;
    let formData = new FormData();
    formData.append("ClientTpId", this.company);
    formData.append("files", this.docFile);
    formData.append("description", this.fileDescription);

    this.$http
      .post("CompanyGangsFile", formData, {
        baseURL: `${this.$store.getters["connection/getApiBase"]}`,
        onUploadProgress: function (progressEvent) {
          this.uploadPercent = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
      .then((response) => {
        if(response.data.data[0].Json.FgSave == 0) {
          this.$notify({
            group: "container",
            title: "¡Error!",
            text: response.data.data[0].Json.Response,
            type: "error",
          });
        } else {
          this.$notify({
            group: 'container',
            title: '¡Exito!',
            text: response.data.data[0].Json.Response,
            type: "success"
          });
        }
        this.staffFile = response.data.data[0].Json.ResponseJson;
        this.fileName = this.docFile.name;
        this.toggleAlert();
        this.resetData();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        //this.$refs.myVueDropzone.setOption("addRemoveLinks",true);
        this.isSubmit = false;
        this.uploadPercent = 0;
        this.responsePercent = 0;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function notifyError(properties = {}) {
  let notifyProperties = {
    group: "container",
    title: "¡ Error !",
    text: "¡" + this.$t("label.errorsPleaseCheck") + "!",
    type: "error",
  };
  notifyProperties = Object.assign(notifyProperties, properties);
  this.$notify(notifyProperties);
}
function toggleErrors(item) {
  this.errorsList = [...item.CompanyStaffListJson];
  this.errosModal = true;
}
function deleteConfirmation(item) {
  this.$swal
    .fire(
      alertPropertiesHelpers(this, {
        text: `${this.$t("label.deleteFile")}`,
      })
    )
    .then((result) => {
      if (result.isConfirmed) {
        this.deactivateFile(item);
      }
    });
}
function deactivateFile(item) {
  this.isDelete = true;

  let GangsFileJson = {
    CompanyStaffFileId: item.CompanyStaffFileId,
  };

  this.$http
    .post("CompanyGangsFile-deactivate", GangsFileJson, {
      root: "GangsFileJson",
    })
    .then((response) => {
      this.$notify({
        group: "container",
        title: "¡Exito!",
        text: response.data.data.Response,
        type: "success",
      });
      this.getHistoricalList();
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isDelete = false;
    });
}

// COMPUTED
function formatedStaff() {
  return this.itemsStaff.map((staff) =>
    Object.assign({}, staff, {
      Nro: staff.Nro,
      Name: staff.Name,
      LastName: staff.LastName,
      Cedula: staff.NumberId,
      Company: staff.ClientName,
      Usuario: staff.TransaLogin ? staff.TransaLogin : "N/A",
      Fecha: staff.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(staff.TransaRegDate)
        : "N/A",
      Status: staff.Status,
      _classes: staff.Status === "INACTIVO" ? "table-danger" : "",
      _cellClasses: this.cellTableStaffClasses,
    })
  );
}
function formatedHistorical() {
  let index = 1;
  return this.itemsHistorical.map((historical) =>
    Object.assign({}, historical, {
      Nro: index++,
      FileName: historical.FileName,
      Company: historical.ClientName,
      Resources: historical.Lines,
      RightRows: historical.Correct,
      WrongRows: historical.Error,
      Usuario: historical.TransaLogin ? historical.TransaLogin : "N/A",
      Fecha: historical.TransaRegDate
        ? DateFormater.formatOnlyDateWithSlash(historical.TransaRegDate)
        : "N/A",
      Status: historical.Status,
      _classes: historical.Status === "INACTIVO" ? "table-danger" : "",
      _cellClasses: this.cellTableHistoricalClasses,
    })
  );
}
function dropZoneOptions() {
  const token = this.user.token;
  return {
    url: `${process.env.VUE_APP_API_BASE}files/`,
    autoProcessQueue: false,
    maxFiles: 1,
    addRemoveLinks: true,
    dictRemoveFile: `${this.$t("label.delete")}`,
    dictCancelUpload: `${this.$t("label.cancelUpload")}`,
    maxfilesexceeded(files) {
      this.removeAllFiles();
      this.addFile(files);
    },
    acceptedFiles: ".xlsx",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
}
function companiesOptions() {
  return this.companies.map((company) =>
    Object.assign({}, company, {
      label: company.ClientName,
      value: company.ClientTpId,
    })
  );
}
function isCompaniesEmpty() {
  return this.companies.length == 0;
}
function getFormatURL() {
  return `${this.$store.getters["connection/getBase"]}Download/FormatoMigracionCuadrillas.xlsx`;
}

export default {
  name: "staff-list",
  mixins: [General, Reportes, StaffMixin],
  validations: FileUploadValidation,
  directives: UpperCase,
  data,
  components: {
    StaffModal,
    AlertModal,
    ErrorsModal,
    CustomTabs,
    CustomTab,
    vueDropzone: vue2Dropzone,
  },
  methods: {
    sendingEvent,
    deleteFile,
    DropzoneError,
    loadData,
    getStaffList,
    handleTab,
    getHistoricalList,
    handleSubmit,
    toggleModal,
    toggleAlert,
    resetData,
    submitStaffFile,
    notifyError,
    toggleErrors,
    deleteConfirmation,
    deactivateFile,
  },
  watch: {
    refrescarDocs: function () {
      if (this.refrescarDocs) {
        this.refrescarDocs = false;
      }
    },
    activeTab: function (newTab) {
      if (newTab == 0) {
        this.loadData(true);
      }
      if (newTab == 1) {
        this.getHistoricalList();
      }
    },
    modal: function (val) {
      if (!val) {
        this.edit = false;
        this.companyStaffId = "";
        this.staffFile = [];
        this.fileName = "";
      }
    },
    alertModal: function (val) {
      if (!val) {
        this.staffFile = [];
        this.fileName = "";
        this.getStaffList(this.company);
      }
    },
    errosModal: function (val) {
      if (!val) {
        this.errorsList = [];
      }
    },
    docFile: function (val) {
      if (val) {
        this.fileDescription = val.name;
      }
    },
    company: function(val) {
      this.getStaffList(val);
    },
  },
  computed: {
    fieldsStaff,
    fieldsHistorical,
    formatedStaff,
    dropZoneOptions,
    companiesOptions,
    isCompaniesEmpty,
    cellTableStaffClasses,
    cellTableHistoricalClasses,
    formatedHistorical,
    getFormatURL,
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  created() {
    this.activeTab = 0;
    this.company = "";
    this.loadData();
  },
  mounted() {},
};
</script>
<style scoped>
.upload-progress {
  width: 100%;
}
</style>
